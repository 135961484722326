
.pac-logo:after {
    display: none !important
}

.pac-container {
    font-family: var(--ec-fonts-body);
    z-index: var(--ec-zIndices-tooltip);
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid;
    border-color: var(--ec-colors-gray-500);
    transform: translateY(1px);
    padding: 5px 20px;
    width: calc(calc(100vw / 3) - 50px) !important;
}

/*  Due CSS Specifiy and modal layout on CC Store Locator, we need this workaround, autocomplete panel 
    from Google will be placed outside of the main object
    making the manipulation and styling a bit difficult */
.store-locator__autocomplete {
    width: calc(calc(100vw / 3) - 55px) !important;
}

.pac-item {
    padding: 15px 0;
    font-size: var(--ec-fontSizes-desktopBodyTextSmall);
    font-weight: 700;
    line-height: normal;
    align-items: center;
    cursor: pointer;
    color: var(--ec-colors-gray-500);
    border: none;
    border-bottom: 1px solid #D2D2D2
}

.pac-item:last-child {
    border-bottom: none
}

.pac-item:hover {
    background-color: transparent !important
}

.pac-icon,.pan-icon-marker {
    display: none
}

.pac-item-query {
    font-size: var(--ec-fontSizes-desktopBodyTextSmall);
    color: var(--ec-colors-gray-500);
}

.pac-matched {
    color: black
}

@media screen and (max-width: 768px) {
    .pac-item-query {
        font-size: var(--ec-fontSizes-mobileBodyTextNormal);
    }

    .pac-item {
        font-size: var(--ec-fontSizes-mobileBodyTextNormal);
    }

    .pac-container {
        width: calc(100vw - 30px) !important;
        left: 0;
        transform: translateY(-4px);
    }
   
}
   


